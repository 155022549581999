import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile/edit',
      name: 'editProfile',
      component: () => import('@/views/EditProfile.vue'),
      meta: {
        pageTitle: 'Edit Profile',
        breadcrumb: [
          {
            text: 'Edit Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/manage',
      name: 'manageProducts',
      component: () => import('@/views/ProductManager.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/upload',
      name: 'uploadProducts',
      component: () => import('@/views/UploadProducts.vue'),
      meta: {
        pageTitle: 'Upload products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Upload',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/manage/:id',
      name: 'editProduct',
      component: () => import('@/views/EditProduct.vue'),
      meta: {
        pageTitle: 'Edit product',
        breadcrumb: [
          {
            text: 'Products',
            url: '/products/manage', // TODO: Not working,
            active: false,
          },
          {
            text: 'Manage',
            active: true,
          },
          {
            text: 'Edit product',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations',
      name: 'integrations',
      component: () => import('@/views/Integrations.vue'),
      meta: {
        pageTitle: 'Integrations',
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // Update page title
  document.title = `Portstock Portal | ${to.meta.pageTitle}`

  // Check if user is logged in
  if (!isUserLoggedIn() && to.name !== 'login' && to.name !== 'register') {
    next({ name: 'login' })
  } else {
    next()
  }

  // If user is logged in redirect login and register pages
  if (isUserLoggedIn() && (to.name === 'login' || to.name === 'register')) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
