import axios from '@axios'

export default {
  authentication: {
    login(email, password) {
      return axios.post('jwt-auth/v1/token', {
        username: email,
        password
      })
    }
  },

  users: {
    me() {
      return axios.get('wp/v2/users/me')
    },

    update(user) {
      return axios.put(`wp/v2/users/me`, user)
    },

    keys: {
      all() {
        return axios.get('wp/v2/users/me/application-passwords')
      },

      create(key) {
        return axios.post(`wp/v2/users/me/application-passwords`, key)
      },

      delete(id) {
        return axios.delete(`wp/v2/users/me/application-passwords/${id}`)
      },
    }
  },

  images: {
    all(per_page, page) {
      const userData = JSON.parse(localStorage.getItem('userData'))

      return axios.get('/wp/v2/ps_product', {
        params: {
          author: userData.id,
          per_page: per_page,
          page: page,
        }
      })
    },

    get(id) {
      return axios.get(`/wp/v2/ps_product/${id}`)
    },

    update(id, updated) {
      return axios.post(`/wp/v2/ps_product/${id}`, updated)
    },

    delete(id) {
      return axios.delete(`/wp/v2/ps_product/${id}`)
    },
  },

  stats: {
    overview() {
      return axios.get('stats/overview')
    },
  },
}
