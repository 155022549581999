import Vue from 'vue'
import axios from 'axios'

import { $themeConfig } from '@themeConfig'

const axiosIns = axios.create({
  baseURL: $themeConfig.app.apiUrl,
})

Vue.prototype.$http = axiosIns

export default axiosIns
